<template>
  <div class="main">
    <Nav></Nav>
    <div class="box">
      <div class="centerBox" v-if="type">
        <div class="title">修改密码</div>
        <div class="formBox">
          <el-form
            ref="resetPwdForm"
            :model="resetPwdForm"
            class="resetPwdForm"
            :rules="resetPwdRules"
          >
            <el-form-item prop="password">
              <el-input
                show-password
                placeholder="请输入旧密码"
                v-model="resetPwdForm.password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="newPassword">
              <el-input
                show-password
                placeholder="请输入新密码"
                v-model="resetPwdForm.newPassword"
              ></el-input>
            </el-form-item>
            <el-form-item prop="newPassword2">
              <el-input
                show-password
                placeholder="请确认新密码"
                v-model="resetPwdForm.newPassword2"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="resetPwd"
                :loading="loading"
                :disabled="disabled"
                >修改密码</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="centerBox" v-else>
        <div class="title">重置密码</div>
        <div class="stepsBox">
          <div :class="formFlag ? 'steps1' : 'steps1-show'">
            <div class="steps-l">
              <span>1</span>
              <span>填写账号</span>
            </div>
            <div class="steps-r"></div>
          </div>
          <div :class="formFlag ? 'steps2' : 'steps2-show'">
            <div class="steps-l"></div>
            <div class="steps-r">
              <span>2</span>
              <span>设置新密码</span>
            </div>
          </div>
        </div>
        <div class="formBox">
          <el-form
            ref="form1"
            :model="form"
            class="form1"
            :rules="rules1"
            v-show="formFlag"
          >
            <el-form-item prop="phone">
              <el-input
                placeholder="请输入手机号码"
                v-model="form.phone"
              ></el-input>
            </el-form-item>
            <el-form-item prop="vCode">
              <div class="codeBox">
                <el-input
                  placeholder="请输入验证码"
                  v-model="form.vCode"
                ></el-input>
                <el-divider direction="vertical"></el-divider>
                <div
                  style="width: 80px; text-align: center; display: inline-block"
                >
                  <span class="codeText" v-if="!codeFlag" @click="getCode"
                    >获取验证码</span
                  >
                  <span class="codeText" v-else>{{ authTime }}</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="next">下一步</el-button>
            </el-form-item>
          </el-form>

          <el-form ref="form2" :model="form2" class="form2" v-show="!formFlag">
            <el-form-item>
              <el-input
                placeholder="请输入新密码"
                v-model="form2.password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="请确认新密码"
                v-model="form2.password2"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="ok"
                :loading="loading"
                :disabled="disabled"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- <Footer class="footer"></Footer> -->

    <el-dialog
      :visible.sync="dialogVisible"
      width="450px"
      :show-close="false"
      top="20vh"
    >
      <div class="resetTrue">
        <i class="el-icon-success"></i>
        <span>重置密码成功!</span>
        <el-link type="primary" href="/login">继续登陆 >></el-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Nav from '../../components/hometitle/hometitle.vue'
import Footer from '@/components/foot/foot.vue'
import { sendSmsCode, forgetPwd, updatePwd } from '@/api/login/login'
export default {
  name: '',
  components: {
    Nav,
    Footer,
  },
  data() {
    var validateResetPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'))
      } else {
        if (this.resetPwdForm.password.length < 6) {
          callback(new Error('请输入正确格式的密码'))
        }
        callback()
      }
    }

    var validateResetNewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.resetPwdForm.newPassword.length < 6) {
          callback(new Error('密码长度要大于等于6位'))
        } else {
          let testPassword = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]*$/
          if(!testPassword.test(this.resetPwdForm.newPassword)) {
            callback(new Error("密码包括大小写字母、数字"));
          }
        }
        callback()
      }
    }

    var validateResetNewPassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.resetPwdForm.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    var validatePhone = (rule, value, callback) => {
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('手机号码格式不正确'))
          return
        }
        callback()
      }
    }
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form2.password.length < 6) {
          callback(new Error('密码长度要大于等于6位'))
        } else {
          let testPassword = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]*$/
          if(!testPassword.test(this.form2.password)) {
            callback(new Error("密码包括大小写字母、数字"));
          }
        }
        callback()
      }
    }
    var validatePassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form2.newPwd1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      type: false, //true修改密码 false忘记密码
      resetPwdForm: {
        password: '',
        newPassword: '',
        newPassword2: ''
      },
      resetPwdRules: {
        password: [{ validator: validateResetPassword, trigger: 'blur' }],
        newPassword: [{ validator: validateResetNewPassword, trigger: 'blur' }],
        newPassword2: [{ validator: validateResetNewPassword2, trigger: 'blur' }],
      },
      formFlag: true,
      form: {
        phone: '',
        vCode: '',
      },
      rules1: {
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        vCode: [
          // { validator: validateCode, trigger: 'blur' }
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      form2: {
        password: '',
        password2: '',
      },
      rules2: {
        password: [{ validator: validatePassword, trigger: 'blur' }],
        password2: [{ validator: validatePassword2, trigger: 'blur' }],
      },
      codeFlag: false,
      authTime: 59,
      loading: false,
      disabled: false,
      dialogVisible: false,
    }
  },
  watch: {},
  props: {},
  computed: {},
  created() {
    if(this.$route.query.type == 1) {
      this.type = true
    }
  },
  mounted() {},
  methods: {
    // 修改密码
    resetPwd() {
      this.$refs.resetPwdForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.disabled = true
          let obj = {
            password: this.resetPwdForm.password,
            newPassword: this.resetPwdForm.newPassword,
            id: localStorage.getItem('userId')
          }
          updatePwd(obj).then(res => {
            if(res.success) {
              this.loading = false
              this.$message.success('修改成功')
              setTimeout(() => {
                localStorage.removeItem('userId')
                localStorage.removeItem('Access-Token')
                this.$router.push('/login')
              },500)
            } else {
              this.loading = false
              this.disabled = false
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取验证码
    getCode() {
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!this.form.phone) {
        this.$message.error('请输入手机号码')
        return
      } else {
        if (!regTest.test(this.form.phone)) {
          this.$message.error('手机号码格式不正确')
          return
        }
      }
      this.codeFlag = true
      var authTimetimer = setInterval(() => {
        this.authTime--
        if (this.authTime <= 0) {
          this.codeFlag = false
          this.authTime = 59
          clearInterval(authTimetimer)
        }
      }, 1000)
      sendSmsCode({ phone: this.form.phone }).then(res => {
        console.log(res)
      })
    },
    // 下一步
    next() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          this.formFlag = false
        } else {
          return false
        }
      })
    },
    // 确定
    ok() {
      this.loading = true
      this.disabled = true
      this.$refs.form2.validate(valid => {
        if (valid) {
          let obj = {
            phone: this.form.phone,
            password: this.form2.password,
            account: this.form.vCode,
          }
          forgetPwd(obj).then(res => {
            if (res.success) {
              this.loading = false
              this.dialogVisible = true
            } else {
              this.loading = false
              this.disabled = false
              this.$message.error(res.message)
            }
          })
        } else {
          this.loading = false
          this.disabled = false
          return false
        }
      })
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  .box {
    .centerBox {
      width: 1200px;
      height: 650px;
      background-color: #fff;
      margin: auto;
      margin-top: 140px;
    }

    flex: 1;
  }
  .footer {
  }
}

.centerBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .title {
    font-size: 22px;
    text-align: center;
    margin-top: 80px;
    color: #333;
    font-weight: bolder;
  }
}

.resetPwdForm {
  .el-form-item {
    margin-bottom: 35px;
  }
  .el-input {
    width: 400px;
  }
  .el-button {
    width: 400px;
  }
}

.stepsBox {
  display: flex;
  margin-top: 80px;
}

.steps1 {
  display: flex;
  .steps-l {
    width: 325px;
    height: 40px;
    background-color: #e6f5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    span:nth-child(1) {
      color: #fff;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      background-color: #0099ff;
      margin-right: 20px;
    }
    span:nth-child(2) {
      color: #0099ff;
    }
  }
  .steps-r {
    border-top: 20px solid transparent;
    border-left: 20px solid #e6f5ff;
    border-bottom: 20px solid transparent;
  }
}

.steps1-show {
  display: flex;
  .steps-l {
    width: 325px;
    height: 40px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    span:nth-child(1) {
      color: #f5f5f5;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      background-color: #c4c4c4;
      margin-right: 20px;
    }
    span:nth-child(2) {
      color: #c4c4c4;
    }
  }
  .steps-r {
    border-top: 20px solid transparent;
    border-left: 20px solid #f5f5f5;
    border-bottom: 20px solid transparent;
  }
}

.steps2 {
  display: flex;
  .steps-l {
    border-top: 20px solid #f5f5f5;
    border-left: 20px solid transparent;
    border-bottom: 20px solid #f5f5f5;
  }
  .steps-r {
    width: 325px;
    height: 40px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    span:nth-child(1) {
      color: #f5f5f5;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      background-color: #c4c4c4;
      margin-right: 20px;
    }
    span:nth-child(2) {
      color: #c4c4c4;
    }
  }
}

.steps2-show {
  display: flex;
  .steps-l {
    border-top: 20px solid #e6f5ff;
    border-left: 20px solid transparent;
    border-bottom: 20px solid #e6f5ff;
  }
  .steps-r {
    width: 325px;
    height: 40px;
    background-color: #e6f5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    span:nth-child(1) {
      color: #fff;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      background-color: #0099ff;
      margin-right: 20px;
    }
    span:nth-child(2) {
      color: #0099ff;
    }
  }
}

.formBox {
  flex: 1;
  display: flex;
  align-items: center;
}

.form1 {
  .el-form-item {
    margin-bottom: 35px;
  }
  .el-form-item:nth-child(1) {
    .el-input {
      width: 400px;
    }
  }
  .el-form-item:nth-child(2) {
    .codeBox {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
    .el-input {
      width: 300px;
      ::v-deep .el-input__inner {
        border: none;
      }
    }
    .codeText {
      color: #029aff;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .el-button {
    width: 400px;
  }
}

.form2 {
  .el-form-item {
    margin-bottom: 35px;
  }
  .el-input {
    width: 400px;
  }
  .el-button {
    width: 400px;
  }
}

.resetTrue {
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  i {
    font-size: 50px;
    margin-top: 20px;
    background: linear-gradient(90deg, #51f061ff 0%, #13cf2cff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  span {
    margin-top: auto;
    margin-bottom: 24px;
    font-size: 16px;
  }
}
</style>
